import 'react-tooltip/dist/react-tooltip.css';
import './tf-controls.scss';
import { STATE_LIST } from '../../../../../common/constants';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  getExactPhrases,
  removeFilterChoices,
  selectSelectedFilterChoices,
  selectSelectedTabFilterInfo,
  setSelectedFilterChoices,
  setUserSelectedFilterChoices,
} from '../../../../../redux/reducers/tabFilterSlice';
import { StateEntity } from '../../../../../common/types/EntityTypes';
import classNames from 'classnames';

function TabFilterState(props: { activeField: any; hasOption: string }) {
  const [stateList, setStateList] = useState([] as StateEntity[]);
  const dispatch = useAppDispatch();
  const selectedTabFilter = useAppSelector(selectSelectedTabFilterInfo);
  const selectedStateFilters = useAppSelector(selectSelectedFilterChoices).filter(
    (e) => e.fieldCode === props.activeField?.fieldCode,
  )[0][props.hasOption === 'has' ? 'has' : 'hasNot'];
  const alreadySelectedStateFilters = useAppSelector(selectSelectedFilterChoices).filter(
    (e) => e.fieldCode === props.activeField?.fieldCode,
  )[0][props.hasOption !== 'has' ? 'has' : 'hasNot'];

  useEffect(() => {
    if (props.activeField?.code) {
      initializeStateList();
    }
  }, []);

  function initializeStateList() {
    let newStateList = [] as StateEntity[];
    STATE_LIST.forEach((item) => {
      const userSelectedState = selectedStateFilters.filter((s) => s.text === item.text)[0];
      if (userSelectedState?.externalId) {
        newStateList.push({
          code: item.code,
          text: item.text,
          externalId: userSelectedState.externalId,
          isSelected: true,
        });
      } else {
        newStateList.push(item);
      }
    });
    setStateList(newStateList);
  }

  const handleCheckbox = (state: any) => {
    if (!state.isSelected) {
      dispatch(
        setSelectedFilterChoices({
          fieldCode: props.activeField.fieldCode,
          hasOption: props.hasOption,
          [props.hasOption]: {
            action: 'UPDATE',
            isSelected: true,
            text: state.text,
          },
        }),
      );
    } else {
      dispatch(
        removeFilterChoices({
          fieldCode: props.activeField.fieldCode,
          hasOption: props.hasOption,
          ...state,
        }),
      );
    }
    const modifiedStateList = stateList.map((item) => {
      if (item.text === state.text) {
        return {
          ...item,
          isSelected: !state.isSelected,
        };
      }
      return item;
    });
    setStateList(modifiedStateList);
  };

  useEffect(() => {
    if (props.activeField.code === '003m') initializeStateList();
  }, [props.activeField]);

  return (
    <div className='tf-state-controls'>
      {stateList &&
        stateList.map((e) => {
          return (
            <div className='tf-state-content tf-checkbox' key={e.code}>
              <input
                type='checkbox'
                checked={
                  selectedStateFilters.filter((s) => s.text === e.text && s?.action !== 'DELETE')
                    .length > 0
                }
                className={classNames('tf-state-checkbox custom-checkbox', {
                  'checkbox-disabled':
                    alreadySelectedStateFilters.filter(
                      (s) => s.text === e.text && s?.action !== 'DELETE',
                    ).length > 0,
                })}
                disabled={
                  alreadySelectedStateFilters.filter(
                    (s) => s.text === e.text && s?.action !== 'DELETE',
                  ).length > 0
                }
                onChange={() => console.log()}
                id={`checkbox-${e.code}`}
              />
              <label
                htmlFor={`checkbox-${e.code}`}
                onClick={() => {
                  if (
                    alreadySelectedStateFilters.filter(
                      (s) => s.text === e.text && s?.action !== 'DELETE',
                    ).length == 0
                  ) {
                    handleCheckbox(e);
                  }
                }}>
                {`${e.text} (${e.code})`}
              </label>
            </div>
          );
        })}
    </div>
  );
}
export default TabFilterState;

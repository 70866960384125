import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import TabShowWhite from '../../../../assets/images/dashboard/table/tab/TabShowWhite.svg';
import _ from 'lodash';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import TextTruncate from 'react-text-truncate';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {
  CLIENT_NAME_COUNTER_STORAGE_KEY,
  FILTER_SETTINGS_STATE,
  NONE_MENU_ID,
  TAB_ACTION_MENU_ID,
  TAB_VIEW_SHARE_DOC_ID,
  TOOL_TIP_TAB,
} from '../../../../common/constants';
import { ClientEntity } from '../../../../common/types/EntityTypes';
import { TabDnDProps } from '../../../../common/types/dashboard/DashboardUITypes';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectCurrentTab, setSelectedTab } from '../../../../redux/reducers/dashboardSlice';
import { selectRows } from '../../../../redux/reducers/documentsDataSlice';
import {
  addFilterToDB,
  addNewFilter,
  getFilterCount,
  selectClients,
  selectInboxId,
  setClientName,
  updateUserFilterName,
} from '../../../../redux/reducers/metadataSlice';
import { selectMenu, setMenu, setMenuTriggor, selectMenuTriggor, selecteIsTabContextMenuActive } from '../../../../redux/reducers/popupSlice';
import { selectTabActionMetadata, selectTableFontSize } from '../../../../redux/reducers/uiSlice';
import './tab.scss';
import {
  selectSelectedTabFilterInfo,
  setFilterDialogState,
  setSelectedTabFilterInfo,
} from '../../../../redux/reducers/tabFilterSlice';
import TabAddButton from '../../../../assets/images/dashboard/table/tab/NewTabAddButton.svg';
import { getDefaultTab } from '../../../../common/util';
import { getLastViewOrder, insertClient } from '../../../../db/clientDBAction';
import { mapClientEntityToOfflineClientEntity } from '../../../../common/types/Mapper';
import { contextMenu } from 'react-contexify';
import ShareBtn from '../../../../assets/images/dashboard/tab/share_svg.svg';

function Tab(props: TabDnDProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });
  const userFontSize = useAppSelector(selectTableFontSize);
  const customStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    '--custom-font-size': (userFontSize + 1) + 'px',
  };
  const dispatch = useAppDispatch();
  const [isTruncted, setIsTruncated] = useState(false);
  const [activeFilterMenu, setActiveFilterMenu] = useState(false);
  const selectedMenu = useAppSelector(selectMenu);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const clients: ClientEntity[] = useAppSelector(selectClients);
  const clientNames = clients.map(client => client.name).filter(clientName => clientName != 'Inbox');
  const currentClient = clients.find((c) => c.id == props.id);
  const currentClientIndex = _.findIndex(clients, {
    id: props.id,
  });

  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(props.text);
  const [editableFieldInitialValue, setEditableFieldInitialValue] = useState('');
  const inboxId = useAppSelector(selectInboxId);
  const selectMenuTriggorPointer = useAppSelector(selectMenuTriggor)
  const isTabContextMenuActive = useAppSelector(selecteIsTabContextMenuActive);

  const setEditable = () => {
    setIsEditable(true);
  };
  // const setNonEditable = (event: any) => {
  //   setFieldNewValue(event.target.value);
  //   setIsEditable(false);
  // };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      setFieldNewValue(props.text);
      setIsEditable(false);
    } else if (event.key === 'Enter' && fieldNewValue !== '') {
      if (!clientNames.includes(fieldNewValue) || fieldNewValue == editableFieldInitialValue) {
        handleFilterNameUpdate();
      }
    }
  };

  const handleOnBlur = () => {
    setFieldNewValue(props.text);
    setIsEditable(false);
  }

  const handleFilterNameUpdate = () => {
    dispatch(updateUserFilterName({ ...currentClient, name: fieldNewValue })).then((e) => {
      setIsEditable(false);
    });
  };

  const handleDoubleClick = () => {
    if (props.text === 'Inbox') {
      return;
    }
    setEditableFieldInitialValue(props.text);
    setFieldNewValue(props.text);
    setEditable();
    if (inputRef) {
      setTimeout(() => {
        inputRef.current?.focus();
        const inputValue = inputRef.current?.value;
        if (inputValue) {
          inputRef.current?.setSelectionRange(inputValue.length, inputValue.length);
        }
      }, 10);
    }
  };

  function displayMenu(e: React.MouseEvent<HTMLElement>) {
    if (e.currentTarget.offsetParent != null && e.currentTarget.offsetParent != undefined) {
      if ((!isTabContextMenuActive ||  props.id != selectMenuTriggorPointer) 
        || (!isTabContextMenuActive || selectedMenu != TAB_ACTION_MENU_ID)) {
        setActiveFilterMenu(!activeFilterMenu);
        dispatch(setMenu(TAB_ACTION_MENU_ID));
        dispatch(setMenuTriggor(props.id));
        let source = e.currentTarget.getBoundingClientRect();
        contextMenu.show({
          id: selectedMenu,
          props: { tabId: props.id },
          event: e,
          position: {
            x: source.left - 50,
            y:
              currentClient?.default &&
              (currentClientIndex == 0 || currentClientIndex == clients.length)
                ? source.top - 50
                : source.top - 95,
            // ? source.top - 75
            // : source.top - 175,
          },
        });
      } else {
        contextMenu.hideAll();
      }
    }
    e.stopPropagation();
  }

  function displayShareMenu(e: React.MouseEvent<HTMLElement>) {
    if (e.currentTarget.offsetParent != null && e.currentTarget.offsetParent != undefined) {
      if ((!isTabContextMenuActive ||  props.id != selectMenuTriggorPointer) 
        || (!isTabContextMenuActive || selectedMenu != TAB_VIEW_SHARE_DOC_ID)) {
        setActiveFilterMenu(!activeFilterMenu);
        dispatch(setMenu(TAB_VIEW_SHARE_DOC_ID));
        dispatch(setMenuTriggor(props.id));
        let source = e.currentTarget.getBoundingClientRect();
        contextMenu.show({
          id: selectedMenu,
          props: { tabId: props.id },
          event: e,
          position: {
            x: source.left - 130,
            y: source.top - 190
          },
        });
      } else {
        contextMenu.hideAll();
      }
    }
    e.stopPropagation();
  }

  const tabActionMetadata = useAppSelector(selectTabActionMetadata);

  const selectedTab = useAppSelector(selectCurrentTab);
  const selectedRows = useAppSelector(selectRows);
  const selectedTabFilter = useAppSelector(selectSelectedTabFilterInfo);

  const getUnReadCountForClient = useCallback(() => {
    const tabRows = selectedRows[props.id];
    if (tabRows === undefined || tabRows.length == 0) return 0;
    else {
      if (_.isArray(tabRows)) return tabRows?.filter((tab) => !tab.read).length;
    }
  }, [selectedRows]);

  const handleChange = async (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    setFieldNewValue(newValue);
    // if (newValue !== '') {
    //   setFieldNewValue(newValue);
    //   // dispatch(  setClientName({ id: props.id, name: newValue }));
    // }
  };
  const [newTabCount, setNewTabCount] = useState(
    _.toInteger(localStorage.getItem(CLIENT_NAME_COUNTER_STORAGE_KEY)),
  );

  const addFilter = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    let defaultTab = getDefaultTab(newTabCount);
    setNewTabCount(newTabCount + 1);

    // const lastRecord = await getLastViewOrder();
    // if (lastRecord) {
    dispatch(getFilterCount('New Filter')).then((filterCountResp) => {
      const filterCount = filterCountResp.payload['data'];
      defaultTab.name = 'New Filter' + filterCount;
      defaultTab.viewOrder = filterCount;
      dispatch(addFilterToDB(defaultTab.name)).then((resp) => {
        if (resp.payload) {
          defaultTab.id = resp.payload?.['data'];
          insertClient(mapClientEntityToOfflineClientEntity(defaultTab, false))?.then(() => {
            dispatch(addNewFilter(defaultTab));
            dispatch(setSelectedTab(defaultTab.id));
            dispatch(setSelectedTabFilterInfo(defaultTab));
            dispatch(setFilterDialogState(FILTER_SETTINGS_STATE.FILTER_RENAME));
          });
        }
      });
    });
  };

  return (
    <div
      ref={setNodeRef}
      style={customStyle}
      {...attributes}
      {...listeners}
      onDoubleClick={handleDoubleClick}
      className='tab-editable-value'>
      {!isEditable && (
        <div
          data-tooltip-id={TOOL_TIP_TAB}
          data-tooltip-content={fieldNewValue}
          data-tooltip-hidden={!isTruncted}
          className={classNames('tab', {
            'selected-tab': _.isEqual(selectedTab, props?.id),
            'selected-tab-filter': _.isEqual(selectedTabFilter?.name, props?.text),
          })}>
          <TextTruncate
            containerClassName=''
            line={2}
            text={props.text}
            onTruncated={() => setIsTruncated(true)}></TextTruncate>
          <div className='tab-name-unread-count'>
            <span
              className={classNames(
                'unread-count',
                // selectedTab?.toLowerCase() == props?.id?.toLowerCase()
                _.isEqual(selectedTab, props?.id)
                  ? 'unread-count-selected'
                  : 'unread-count-unselected',
                getUnReadCountForClient() == 0 ? 'visible-hidden' : 'visibilty-not-hiddent',
              )}>
              {getUnReadCountForClient()}
            </span>
            {(
              <img
                className={classNames( 'tab-share-btn ', {
                  hide: inboxId === props.id,
                })}
                title='Share'
                onClick={(event) => {
                  displayShareMenu(event);
                }}
                src={ShareBtn}></img>
            )}
            {(
              <img
                className={classNames( 'tab-pointer',{
                  hide: clients.length == 1,
                })}
                width={tabActionMetadata.widthPX}
                height={tabActionMetadata.heightPX}
                onClick={(event) => {
                  displayMenu(event);
                }}
                src={
                  selectMenuTriggorPointer === props.id && selectedMenu == TAB_ACTION_MENU_ID ?
                   tabActionMetadata.hide : tabActionMetadata.show
                }></img>
            )}
          </div>
        </div>
      )}
      {props.text === 'Inbox' && (
        <div className='add-tab' onClick={addFilter}>
          <img src={TabAddButton} alt='+' />
          <div>Add Tab</div>
        </div>
      )}
      {isEditable && (
        <input
          ref={inputRef}
          onKeyDown={(event) => handleKeyDown(event)}
          onChange={(event) => handleChange(event)}
          onBlur={() => handleOnBlur()}
          type='text'
          value={fieldNewValue}
          style={{ height: 'inherit' }}
        />
      )}
      <Tooltip className='tab-tooltip' id={TOOL_TIP_TAB}></Tooltip>
    </div>
  );
}
export default Tab;

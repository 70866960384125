import classNames from 'classnames';
import { Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  deleteDocument,
  updateDocumentsStatusDeleted,
} from '../../../redux/reducers/documentsStatusSlice';
import { deleteDocuments } from '../../../db/documentDBAction';
import { selectRows, updateLeaseDocumentDeleted } from '../../../redux/reducers/documentsDataSlice';
import { selectSelectedDocumentId, setDeSelectDocument, setDocumentDeleted, setSelectedDocument } from '../../../redux/reducers/dashboardSlice';
import { selectInboxId } from '../../../redux/reducers/metadataSlice';

function RowContextMenu(props: any) {
  const dispatch = useAppDispatch();
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const inboxId = useAppSelector(selectInboxId);
  
  const handleDeleteDocument = () => {
    const document = props.propsFromTrigger.data;
    deleteDocuments([document.id]).then(() => {
      dispatch(deleteDocument(document.id)).then(() => {
        if (selectedDocumentId === document.id) {
          dispatch(setDeSelectDocument);
        }
       
        if (inboxId !== document.client) {
          dispatch(
            updateLeaseDocumentDeleted({
              documentId: document.id,
              clientId: inboxId,
            }),
          );
        }
        dispatch(
          updateLeaseDocumentDeleted({
            documentId: document.id,
            clientId: document.client,
          }),
        );
        dispatch(
          updateDocumentsStatusDeleted({
            documentId: document.id,
            isDeleted: true,
          }),
        );
        dispatch(setDocumentDeleted(true));
      });
    });
  };
  return (
    <section>
      <Item
        key='rowContextMenu'
        id='rowContextMenu'
        className={classNames('context-menu-item')}
        onClick={handleDeleteDocument}>
        <span className={classNames('menu-status-text', 'context-menu-text')}>Delete LOI</span>
      </Item>
    </section>
  );
}
export default RowContextMenu;

import { useEffect, useRef, useState } from 'react';
import 'react-edit-text/dist/index.css';
import { FieldValueMetadata } from '../../../common/types/dashboard/DashboardUITypes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectSelectedDocumentAuthor,
  selectSelectedDocumentHTML,
  selectSelectedDocumentMarket,
  selectSelectedDocumentName,
  selectSelectedDocumentValues,
  selectSelectedDocumentValuesMapped,
  setFieldInEditMode,
  setTextCopiedFromLHS,
} from '../../../redux/reducers/dashboardSlice';
import { selectDocumentID } from '../../../redux/reducers/documentSlice';
import { VType } from '../../../redux/reducers/documentsSlice';
import { setClose } from '../../../redux/reducers/popupSlice';
import { revertDocumentFieldValue, updateDocumentFieldValue } from '../../../redux/thunks';
import './Edit.scss';
import EditableValue from './editable-value/EditableValue';
import { PuffLoader } from 'react-spinners';
import classNames from 'classnames';
import { selectAuthors, selectMarkets } from '../../../redux/reducers/metadataSlice';
import { DEFAULT_AUTHOR_NAME, DEFAULT_MARKET } from '../../../common/constants';
import {
  selectCustomLineHeight,
} from '../../../redux/reducers/uiSlice';
import { getUpdatedCustomFontSize } from '../../../common/util';
import { selectUserAppFontSize } from '../../../redux/reducers/settingsSlice';

function Edit() {
  const dispatch = useAppDispatch();
  const docId = useAppSelector(selectDocumentID);
  const html = useAppSelector(selectSelectedDocumentHTML);

  const author = useAppSelector(selectSelectedDocumentAuthor);
  const market = useAppSelector(selectSelectedDocumentMarket);

  const authors = useAppSelector(selectAuthors);
  const markets = useAppSelector(selectMarkets);
  const documentValues = useAppSelector(selectSelectedDocumentValues);

  const editDialogRef = useRef<HTMLDivElement>(null);

  // File Name
  const fileName = useAppSelector(selectSelectedDocumentName);

  const [prevFieldCodeAccessed, setPrevFieldCodeAccessed] = useState('');

  const [selectedField, setSelectedField] = useState('');

  const values = useAppSelector(selectSelectedDocumentValuesMapped);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [textCopied, setTextCopied] = useState('');

  function getLOIDate() {
    const dateValue = documentValues?.find((value) => value.field === '008b');
    if (dateValue == null || dateValue == undefined) {
      return '';
    }
    return dateValue.value;
  }

  function getAuthor(authorId: any) {
    let selectedAuthor = authors?.find((author) => authorId == author.externalId);
    if (selectedAuthor == null || selectedAuthor == undefined) {
      selectedAuthor = authors?.find((author) => author.name == DEFAULT_AUTHOR_NAME);
    }

    return selectedAuthor?.name;
  }

  function getMarkets(marketId: any) {
    let selectedMarket = markets?.find((market) => marketId == market.externalId);

    if (selectedMarket == null || selectedMarket == undefined) {
      selectedMarket = markets?.find((market) => market.name == DEFAULT_MARKET);
    }
    return selectedMarket?.name;
  }

  function handleSave(input: { name: string; value: string; previousValue: string; engineValue: string }): void {
    if (input.value !== input.previousValue) {
      dispatch(
        updateDocumentFieldValue({
          docId: docId,
          fieldCode: input.name,
          newValue: input.value,
          oldValue: input.previousValue,
          engineValue: input.engineValue,
        }),
      );
    }
  }

  function handleRevert(value: VType) {
    let field = {
      docId: docId,
      fieldCode: value.id.toString(),
      newValue: value.prevValue,
      oldValue: value.value,
      engineValue: value.engineValue,
    };
    dispatch(revertDocumentFieldValue(field)).then(() => {
      field.fieldCode = value.key;
      // dispatch(setFieldValue(field));
    });
  }

  const addHighlight = (element: HTMLCollection) => {
    for (let i = 0; i <= element.length - 1; i++) {
      let parentElement = element.item(i);
      if (parentElement?.hasChildNodes) {
        const childElements = parentElement.querySelectorAll('.hl-yellow');
        childElements.forEach((child) => {
          child?.setAttribute('style', 'background-color: #FAD47F !important');
        });
      }
      parentElement?.setAttribute('style', 'background-color: #FAD47F !important');
    }
  };

  const removeHightlight = (element: HTMLCollection, selectedFieldCode: string) => {
    for (let i = 0; i <= element.length - 1; i++) {
      let pointerElement = element.item(i);
      pointerElement?.classList.add('hl-yellow');
      const searchString = 'hl-yellow';
      const parentElement: any = pointerElement?.parentElement;
      const parentStartIndex = Number(parentElement.className.indexOf(searchString));
      if (parentElement && parentStartIndex > -1) {
        const prevHlParent = parentElement.className
          .substring(parentStartIndex + searchString.length)
          .trim();
        if (selectedFieldCode === prevHlParent) {
          return;
        }
      }
      if (pointerElement?.hasChildNodes) {
        const childElements = pointerElement.querySelectorAll('.hl-yellow');
        childElements.forEach((child) => {
          const searchString = 'hl-yellow';
          const startIndex = child.className.indexOf(searchString);
          const prevHlChild = child.className.substring(startIndex + searchString.length).trim();
          if (selectedFieldCode !== prevHlChild) {
            child?.setAttribute('style', '');
            return;
          }
        });
      }
      pointerElement?.setAttribute('style', '');
    }
  };

  const handleLeftToRight = (fieldCode: string) => {
    const prevFieldFromLocal = localStorage.getItem('prevAccessedField')?.toString();
    console.log("Previously Accessed Field ---" + prevFieldFromLocal);
    if (prevFieldFromLocal === '') {
      setPrevFieldCodeAccessed(fieldCode);
      localStorage.setItem('prevAccessedField', fieldCode);
    }
    if (fieldCode.indexOf(' ')) fieldCode = fieldCode.split(' ')[0].toString();
    if (fieldCode != prevFieldFromLocal) {
      setSelectedField(fieldCode);
      const pageElements = iframeRef.current?.contentWindow?.document.getElementsByClassName(
        'hl-yellow ' + fieldCode,
      );

      if (pageElements) {
        if (pageElements && pageElements.length > 0) {
          addHighlight(pageElements);
          if (prevFieldFromLocal != '') {
            const prevFieldElement =
              iframeRef.current?.contentWindow?.document.getElementsByClassName(
                String(prevFieldFromLocal),
              );
            if (prevFieldElement != undefined && prevFieldElement?.length > 0) {
              removeHightlight(prevFieldElement, fieldCode);
            }
          }
          localStorage.setItem('prevAccessedField', fieldCode);
          setPrevFieldCodeAccessed(fieldCode);
        }
        const targetDiv = document.getElementById(fieldCode);
        if (targetDiv && editDialogRef.current) {
          targetDiv.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    }
  };

  const handleNavigation = (fieldCode: string, fieldValue: string) => {
    if (fieldValue == '---') {
      setSelectedField(fieldCode);
      if (prevFieldCodeAccessed != '') {
        const prevFieldElement =
          iframeRef.current?.contentWindow?.document.getElementsByClassName(prevFieldCodeAccessed);
        if (prevFieldElement != undefined && prevFieldElement?.length > 0) {
          removeHightlight(prevFieldElement, fieldCode);
        }
      }

      setPrevFieldCodeAccessed(fieldCode);
      localStorage.setItem('prevAccessedField', fieldCode);
      return;
    }

    if (fieldCode != prevFieldCodeAccessed) {
      setSelectedField(fieldCode);
      const pageElements = iframeRef.current?.contentWindow?.document.getElementsByClassName('pf');
      if (pageElements) {
        for (let index = 0; index < pageElements.length; index++) {
          const page = pageElements[index];
          const fieldElement = page.getElementsByClassName('hl-yellow ' + fieldCode);
          if (fieldElement && fieldElement.length > 0) {
            page?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
            fieldElement[0]?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
            if (prevFieldCodeAccessed != '') {
              const prevFieldElement =
                iframeRef.current?.contentWindow?.document.getElementsByClassName(
                  prevFieldCodeAccessed,
                );
              if (prevFieldElement != undefined && prevFieldElement?.length > 0) {
                removeHightlight(prevFieldElement, fieldCode);
              }
            }
            addHighlight(fieldElement);
          }
        }

        setPrevFieldCodeAccessed(fieldCode);
        localStorage.setItem('prevAccessedField', fieldCode);
      }
    }
  };

  const getValues = (values: FieldValueMetadata[]) => {
    if (values != undefined) {
      return values
        .filter((value) => Object.keys(value).length > 0 && value.name !== 'Author')
        .sort((v1, v2) => v1.order - v2.order)
        .map((value: FieldValueMetadata, index: number) => {
          return (
            <div key={value.field} id={value.field} className='value-pair'>
              <label
                style={{ cursor: 'default', paddingLeft: '0rem 0.3125rem 0rem 0.3125rem' }}
                className='value-label'>
                {value.name}
              </label>
              <div
                style={{
                  width: '98%',
                }}
                onClick={() => handleNavigation(value.field, value.value)}>
                <EditableValue
                  field={value}
                  isSelected={selectedField == value.field}></EditableValue>
              </div>
            </div>
          );
        });
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== process.env.PUBLIC_URL) {
        return;
      }
      if (event?.data?.textToBeCopied && event?.data?.textToBeCopied?.trim() !== '') { 
        dispatch(setTextCopiedFromLHS(event.data.textToBeCopied?.trim()))
      } else {
        handleLeftToRight(event?.data?.className?.trim());
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleLoad = () => {
    const pdfContainerElement =
      iframeRef.current?.contentWindow?.document.getElementById('page-container');
    if (pdfContainerElement) {
      if (pdfContainerElement.scrollWidth > pdfContainerElement.clientWidth) {
        const widthDiff = pdfContainerElement.scrollWidth - pdfContainerElement.clientWidth;
        let zoomRatio = (widthDiff / pdfContainerElement.clientWidth) * 100;
        if (zoomRatio > 90) {
          zoomRatio = (widthDiff / pdfContainerElement.scrollWidth) * 100;
        }
        if (zoomRatio > 40 && zoomRatio < 90) {
          pdfContainerElement.setAttribute('style', `zoom: ${zoomRatio}%;`);
        }
        console.table({
          scrollWidth: pdfContainerElement.scrollWidth,
          clientWidth: pdfContainerElement.clientWidth,
          diff: pdfContainerElement.scrollWidth - pdfContainerElement.clientWidth,
          divByClient: (widthDiff / pdfContainerElement.clientWidth) * 100,
          divByScroll: zoomRatio,
        });
      }
    }
  };

  const userFontSize = Number(useAppSelector(selectUserAppFontSize).value);
  const userLineHeight = useAppSelector(selectCustomLineHeight);
  const customStyle = {
    fontStyle: 'normal',
    '--custom-text-font-size': userFontSize + 'px',
    '--custom-edit-header-tag-font-size': getUpdatedCustomFontSize(
      '--custom-edit-header-tag-font-size',
    ),
    '--custom-font-size': userFontSize + 'px',
    '--custom-line-height': userLineHeight,
    '--custom-close-font-size': getUpdatedCustomFontSize('--custom-close-font-size')
  };

  function generateRandomText(wordCount: number) {
    const words = [
        "lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit",
        "sed", "do", "eiusmod", "tempor", "incididunt", "ut", "labore", "et", "dolore",
        "magna", "aliqua", "ut", "enim", "ad", "minim", "veniam", "quis", "nostrud",
        "exercitation", "ullamco", "laboris", "nisi", "ut", "aliquip", "ex", "ea",
        "commodo", "consequat", "duis", "aute", "irure", "dolor", "in", "reprehenderit",
        "in", "voluptate", "velit", "esse", "cillum", "dolore", "eu", "fugiat", "nulla",
        "pariatur", "excepteur", "sint", "occaecat", "cupidatat", "non", "proident",
        "sunt", "in", "culpa", "qui", "officia", "deserunt", "mollit", "anim", "id", "est", "laborum"
    ];

    let randomText = '';
    for (let i = 0; i < wordCount; i++) {
        const randomIndex = Math.floor(Math.random() * words.length);
        randomText += words[randomIndex] + ' ';
    }

    return randomText.trim() + '.';
}

  return (
    <div className='abstractor' style={customStyle}>
      <div className='absractor-divide'>
        <div className='iframe-div'>
          <div className='ab-frame-header'>
            <div className='abstractor-file-name'>&nbsp;{fileName}</div>
            <div className='abstractor-file-details'>
              <div className='ab-file-container'>
                <span className='abstractor-details-key' onClick={() => dispatch(setTextCopiedFromLHS(generateRandomText(3)))}>LOI Date: </span>
                <span className='abstractor-details-value'>{getLOIDate()}</span>
              </div>
              <div className='ab-file-container'>
                <span className='abstractor-details-key'>Market: </span>
                <span className='abstractor-details-value'>{getMarkets(market)}</span>
              </div>
              <div className='ab-file-container'>
                <span className='abstractor-details-key'>Author: </span>
                <span className='abstractor-details-value'>{getAuthor(author)}</span>
              </div>
            </div>
          </div>
          <div
            className={classNames('html-loader', {
              hide: html != undefined,
            })}>
            <PuffLoader color='#FFB000' speedMultiplier={2} />
          </div>

          <iframe
            className={classNames({ hide: html == undefined })}
            ref={iframeRef}
            srcDoc={html}
            onLoad={handleLoad}
            height='100%'
            width='100%'></iframe>
        </div>
        <div ref={editDialogRef} className='absractor-values'>
          <div className='value-container'>{getValues(values)}</div>
          <div className='action-container'>
            <button
              onClick={() => {
                dispatch(setClose());
                dispatch(setFieldInEditMode(''));
              }}
              className='button-grey action-buttons export-button edit-close-button'>
              <span className='button-text'>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;

import _ from 'lodash';
import { ClientEntity } from '../../../common/types/EntityTypes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  removeFilterFromDB,
  selectClients,
  setClients,
} from '../../../redux/reducers/metadataSlice';
import { selectMenuTriggor, setShowFilterDeletePopup } from '../../../redux/reducers/popupSlice';
import './DeleteTabWarning.scss';
import { selectCurrentTab, setSelectedTab } from '../../../redux/reducers/dashboardSlice';
function DeleteTabWarning() {
  const dispatch = useAppDispatch();
  const tabActionTriggor = useAppSelector(selectMenuTriggor);
  const clients: ClientEntity[] = useAppSelector(selectClients);
  const currentClientIndex = _.findIndex(clients, {
    id: tabActionTriggor,
  });
  const selectedTabId = useAppSelector(selectCurrentTab);

  function handleDelete() {
    const filterToBeDeleted = clients[currentClientIndex];
    if (selectedTabId === filterToBeDeleted.id) {
      dispatch(setSelectedTab(clients[0].id));
    }
    dispatch(removeFilterFromDB(filterToBeDeleted.id));
    dispatch(setShowFilterDeletePopup(false));
  }

  function handleCancel() {
    dispatch(setShowFilterDeletePopup(false));
  }

  return (
    <section className='dtw-container'>
      <div className='dtw-warning-text'>Are you sure you want to delete this Tab?</div>
      <div className='dtw-footer'>
        <button className='dtw-button' onClick={handleCancel}>
          <span className='dtw-button-text'>Cancel</span>
        </button>
        <button className='dtw-button dtw-button-yellow' onClick={handleDelete}>
          <span className='dtw-button-text'>Delete</span>
        </button>
      </div>
    </section>
  );
}
export default DeleteTabWarning;

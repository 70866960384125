import classNames from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FieldValueMetadata } from '../../../../common/types/dashboard/DashboardUITypes';
import { upsertFieldValues } from '../../../../db/fieldDBAction';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectFieldInEditMode,
  selectSelectedDocumentId,
  selectTextCopiedFromLHS,
  setFieldInEditMode,
  setTextCopiedFromLHS,
} from '../../../../redux/reducers/dashboardSlice';
import './EditableValue.scss';
import { selectUserAppFontSize } from '../../../../redux/reducers/settingsSlice';
import { selectCustomLineHeight } from '../../../../redux/reducers/uiSlice';
import { updateDocumentFieldValue } from '../../../../redux/thunks';
import TickIcon from '../../../../assets/images/dialog/settings/blackTick.svg';
import TickIconDisabled from '../../../../assets/images/dashboard/table/SaveTickIcon_Disabled.svg';


function EditableValue(props: { field: FieldValueMetadata; isSelected: boolean }) {
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const dispatch = useAppDispatch();
  const field = { ...props.field };
  const initialValue = field.value;
  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(field.value);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const fieldInEditMode = useAppSelector(selectFieldInEditMode);
  const textCopied = useAppSelector(selectTextCopiedFromLHS);

  const handleKeyDown = (event: any) => {
    event.target.style.height = 'auto';
    event.target.style.height = `${event.target.scrollHeight}px`;
    const newValue = event.target.value.trim();
    if (event.key === 'Escape') {
      setIsEditable(false);
      setFieldNewValue(field.value);
    } else if (event.key === 'Enter') {
      handleChange(newValue);
      setIsEditable(false);
    }
  };

  const handleChange = (newValue: string) => {
    upsertFieldValues({
      docId: selectedDocumentId,
      isPushed: false,
      fieldId: field.field,
      newValue: newValue,
      oldValue: field.value,
      isPersisted: false,
    }).then((res) => {
      dispatch(
        updateDocumentFieldValue({
          docId: selectedDocumentId,
          fieldCode: field.field,
          newValue: newValue,
          oldValue: field.value,
          engineValue: field.engineValue,
        }),
      );
    });
    setFieldNewValue(newValue);
    field.prevValue = initialValue;
    field.value = newValue;
  };

  const handleDocumentClick: EventListener = async (event) => {
    if (
      textareaRef.current &&
      event.target instanceof Node &&
      !textareaRef.current.contains(event.target)
    ) {
      // Set the textarea to non-editable when clicked outside
      setIsEditable(false);
      dispatch(setFieldInEditMode(''));
      if (initialValue != fieldNewValue) {
        await upsertFieldValues({
          docId: selectedDocumentId,
          isPushed: false,
          fieldId: field.field,
          newValue: fieldNewValue,
          oldValue: field.value,
          isPersisted: false,
        });

        field.prevValue = field.value;
        field.value = fieldNewValue;
      }
    }
  };
  
  const handleEditMode = (fieldCode: string) => {
    setIsEditable(true);
    dispatch(setFieldInEditMode(fieldCode));
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.select();
      }
    }, 0);
  };

  const handleReset = () => {
    upsertFieldValues({
      docId: selectedDocumentId,
      isPushed: false,
      fieldId: field.field,
      newValue: field.engineValue,
      oldValue: field.value,
      isPersisted: false,
    }).then((res) => {
      dispatch(
        updateDocumentFieldValue({
          docId: selectedDocumentId,
          fieldCode: field.field,
          newValue: field.engineValue,
          oldValue: field.value,
          engineValue: field.engineValue,
        }),
      );
    });
    setFieldNewValue(field.engineValue);
  };

  const handleSaveText = () => {
    upsertFieldValues({
      docId: selectedDocumentId,
      isPushed: false,
      fieldId: field.field,
      newValue: fieldNewValue,
      oldValue: field.value,
      isPersisted: false,
    }).then((res) => {
      dispatch(
        updateDocumentFieldValue({
          docId: selectedDocumentId,
          fieldCode: field.field,
          newValue: fieldNewValue,
          oldValue: field.value,
          engineValue: field.engineValue,
        }),
      );
    });
    setIsEditable(false);
    dispatch(setFieldInEditMode(''));
    field.prevValue = initialValue;
    field.value = fieldNewValue;
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (textCopied !== '' && props.isSelected) {
      console.log('Saving Text --- ' + props.field.field);
      setFieldNewValue(textCopied);
      handleChange(textCopied);
      dispatch(setTextCopiedFromLHS(''));
    }
  }, [textCopied]);

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFieldNewValue(event.target.value);
  };

  const userFontSize = Number(useAppSelector(selectUserAppFontSize).value);
  const userLineHeight = useAppSelector(selectCustomLineHeight);
  const customStyle = {
    cursor: 'pointer',
    height: 'auto',
    fontStyle: 'normal',
    '--custom-text-font-size': userFontSize + 'px',
    '--custom-line-height': userLineHeight,
  };

  return (
    <>
      <div className='value-holder'>
        <div
          style={customStyle}
          className={classNames('value', {
            hide: isEditable && fieldInEditMode === props.field.field,
            'value-edited': props.field.engineValue != fieldNewValue,
            'value-selected': props.isSelected,
            'value-not-selected': !props.isSelected,
          })}
          onDoubleClick={() => handleEditMode(props.field.field)}>
          {fieldNewValue}
        </div>
        <div
          className={classNames('value-reset', {
            hide: fieldNewValue === props.field.engineValue || isEditable,
          })}
          onClick={() => handleReset()}>
          Reset
        </div>
      </div>
      <div className={classNames('value-textbox-container', {
            hide: !(isEditable && fieldInEditMode === props.field.field),
          })}>
        <textarea
          ref={textareaRef}
          className={classNames('value')}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          onBlur={handleSaveText}
          value={fieldNewValue}></textarea>
        {/* <div className={classNames('value-save-tick', {
          'value-tick-disabled' : fieldNewValue === props.field.engineValue
        })} onClick={handleSaveText}>
          <img src={fieldNewValue !== props.field.value ? TickIcon : TickIconDisabled} alt='<' />
        </div> */}
      </div>
    </>
  );
}
export default EditableValue;

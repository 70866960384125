import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { contextMenu } from 'react-contexify';
import { CLIENT_NAME_COUNTER_STORAGE_KEY, TAB_VIEW_MENU_ID } from '../../../common/constants';
import { ClientEntity } from '../../../common/types/EntityTypes';
import { mapClientEntityToOfflineClientEntity } from '../../../common/types/Mapper';
import { CustomDashboardParams } from '../../../common/types/dashboard/DashboardUITypes';
import { getDefaultTab } from '../../../common/util';
import { getLastViewOrder, insertClient } from '../../../db/clientDBAction';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectCurrentTab,
  setDeSelectDocument,
  setSelectedTab,
} from '../../../redux/reducers/dashboardSlice';
import {
  addFilterToDB,
  addNewFilter,
  selectClients,
  selectSelectedClient,
  updateClientOrder,
} from '../../../redux/reducers/metadataSlice';
import { selectMenu, setMenu } from '../../../redux/reducers/popupSlice';
import {
  selectResolutionMetadata,
  selectTabAddMetadata,
  selectTabViewMetadata,
} from '../../../redux/reducers/uiSlice';
import './tab-container.scss';
import Tab from './tab/tab';
import classNames from 'classnames';
import { selectSelectedTabFilterInfo } from '../../../redux/reducers/tabFilterSlice';
import { fetchUserClientsDocuments } from '../../../redux/reducers/documentsDataSlice';
import { selectUserID } from '../../../redux/reducers/userSlice';
import { useAxiosLoader } from '../../../api/axios';

type tabRefType = Map<string, HTMLDivElement | null>;

function TabContainer(props: CustomDashboardParams) {
  const dispatch = useAppDispatch();
  const [overflow, setOverflow] = useState(false);

  const tabContainer = useRef<HTMLDivElement | null>(null);
  const tabHolder = useRef<HTMLDivElement | null>(null);
  const tabRefs = useRef<tabRefType>(new Map());

  const [newTabCount, setNewTabCount] = useState(
    _.toInteger(localStorage.getItem(CLIENT_NAME_COUNTER_STORAGE_KEY)),
  );

  useEffect(() => {
    localStorage.setItem(CLIENT_NAME_COUNTER_STORAGE_KEY, _.toString(newTabCount));
  }, [newTabCount]);

  useEffect(() => {
    if (tabContainer.current?.offsetWidth && tabHolder.current?.scrollWidth)
      setOverflow(tabHolder.current?.scrollWidth > tabContainer.current?.offsetWidth);
  }, [tabContainer.current?.offsetWidth, tabHolder.current?.scrollWidth]);

  const tabAddMetadata = useAppSelector(selectTabAddMetadata);
  const tabViewMetadata = useAppSelector(selectTabViewMetadata);
  const selectedTabFilter = useAppSelector(selectSelectedTabFilterInfo);

  let clients: ClientEntity[] = useAppSelector(selectClients);
  const selectedTab = useAppSelector(selectCurrentTab);
  const selectedClient = useAppSelector(selectSelectedClient);
  const selectedMenu = useAppSelector(selectMenu);
  const resMetadata = useAppSelector(selectResolutionMetadata);
  const userId = useAppSelector(selectUserID);
  const [loader] = useAxiosLoader();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over != null) {
      if (active.id !== over.id) {
        let updatedArray = [...clients];
        const oldIndex = _.findIndex(updatedArray, {
          id: active.id.toString(),
        });
        const newIndex = _.findIndex(updatedArray, {
          id: over.id.toString(),
        });

        const oldTab = { ...updatedArray[oldIndex] };
        const newTab = { ...updatedArray[newIndex] };

        const oldViewOrder = oldTab.viewOrder;
        oldTab.viewOrder = newTab.viewOrder;
        newTab.viewOrder = oldViewOrder;

        updatedArray[oldIndex] = oldTab;
        updatedArray[newIndex] = newTab;

        dispatch(updateClientOrder(arrayMove(updatedArray, oldIndex, newIndex)));
      }
    }
  }

  function displayMenu(e: React.MouseEvent<HTMLElement>) {
    if (e.currentTarget.offsetParent != null && e.currentTarget.offsetParent != undefined) {
      dispatch(setMenu(TAB_VIEW_MENU_ID));
      contextMenu.show({
        id: selectedMenu,
        event: e,
        position: {
          x: e.currentTarget.offsetLeft + window.innerWidth * 0.008,
          y: e.currentTarget.getBoundingClientRect().bottom - window.innerHeight * 0.42,
        },
      });
      e.stopPropagation();
    }
  }

  function handleTab(tabId: string) {
    dispatch(setSelectedTab(tabId));

    const targetClient = clients.find((c) => c.id == tabId);
    moveToTab(targetClient);
    if (clients && clients.length > 0) {
      loader
      dispatch(
        fetchUserClientsDocuments({
          clientIds: [ clients.filter((client) => client.id === targetClient?.id)[0].id ],
          userId: userId?.toString(),
        })
      );
    }

    if (tabId != selectedTab) {
      dispatch(setDeSelectDocument());
    }
  }

  const moveToTab = (targetClient: ClientEntity | undefined) => {
    if (targetClient) {
      tabRefs.current.get(targetClient.id)?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      });
    }
  };

  const handleNavigation = (currentClient: ClientEntity | undefined, isLeft: boolean) => {
    if (currentClient) {
      const targetClient = clients.find((c) =>
        isLeft
          ? c.viewOrder == currentClient.viewOrder - 1
          : c.viewOrder == currentClient.viewOrder + 1,
      );
      if (targetClient) handleTab(targetClient?.id);
    }
  };

  return (
    <div ref={tabContainer} className={classNames('tab-container-common', {
      'pointer-events-none': selectedTabFilter?.name
    })}>
      {/* <div ref={tabHolder} className='start-tab-common'>
        <img
          width={tabAddMetadata.widthPX}
          height={tabAddMetadata.heightPX}
          src={tabAddMetadata.image}
          onClick={addClient}></img>
      </div> */}
      <div ref={tabHolder} className='tab-holder'>
        <DndContext
          collisionDetection={closestCenter}
          sensors={sensors}
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={handleDragEnd}>
          <SortableContext items={clients}>
            {clients?.map((client, index) => (
              <div
                ref={(el) => tabRefs.current.set(client.id, el)}
                className='tab-div'
                key={client.id}
                id={`tabDiv-${client.id}`}
                onClick={() => handleTab(client.id)}>
                <Tab text={client.name} id={client.id} isDefault={client.default} />
              </div>
            ))}
          </SortableContext>
        </DndContext>
      </div>
      {!overflow && <div className='filling-tab'></div>}
      <div className='start-tab-common'>
        <img
          width={tabViewMetadata.widthPX}
          height={tabViewMetadata.heightPX}
          src={tabViewMetadata.image}
          onClick={(event) => displayMenu(event)}></img>
      </div>
      {overflow && (
        <div className={classNames('navigator-tab')}>
          <img
            width={resMetadata.image_dimension.TAB_NAV_LEFT.widthPX}
            height={resMetadata.image_dimension.TAB_NAV_LEFT.heightPX}
            src={
              selectedClient?.viewOrder == clients?.[0]?.viewOrder
                ? resMetadata.image_dimension.TAB_NAV_LEFT.hide
                : resMetadata.image_dimension.TAB_NAV_LEFT.show
            }
            className={classNames({
              'disable-pointer-events': selectedClient?.viewOrder == clients?.[0]?.viewOrder,
            })}
            onClick={() => handleNavigation(selectedClient, true)}></img>
          <img
            width={resMetadata.image_dimension.TAB_NAV_RIGHT.widthPX}
            height={resMetadata.image_dimension.TAB_NAV_RIGHT.heightPX}
            className={classNames({
              'disable-pointer-events':
                selectedClient?.viewOrder == clients?.[clients?.length - 1]?.viewOrder,
            })}
            src={
              selectedClient?.viewOrder == clients?.[clients?.length - 1]?.viewOrder
                ? resMetadata.image_dimension.TAB_NAV_RIGHT.hide
                : resMetadata.image_dimension.TAB_NAV_RIGHT.show
            }
            onClick={() => handleNavigation(selectedClient, false)}></img>
        </div>
      )}
    </div>
  );
}
export default TabContainer;

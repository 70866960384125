import { Menu } from 'react-contexify';
import 'react-tooltip/dist/react-tooltip.css';
import {
  AUTHOR_MENU_ID,
  CONTEXT_MENU_ID,
  DELETE_FILTER_WARNING_ID,
  FILTER_SETTINGS_STATE,
  MARKET_MENU_ID,
  NONE_MENU_ID,
  STATUS_FILTER_MENU_ID,
  STATUS_MENU_ID,
  TAB_ACTION_MENU_ID,
  TAB_VIEW_MENU_ID,
  TAB_VIEW_SHARE_DOC_ID,
} from '../../common/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectIsFilterDeletePopup, selectMenu, setMenu, setIsTabContextMenuActive } from '../../redux/reducers/popupSlice';
import Author from '../popup/author/Author';
import LeaseFilter from '../popup/filter/LeaseFilter';
import LeaseStatus from '../popup/lease-status/LeaseStatus';
import Market from '../popup/market/Market';
import TabAction from '../popup/tab-action/TabAction';
import TabList from '../popup/tab-list/TabList';
import './content.scss';
import TabContainer from './tab/tab-container';
import Table from './table/Table';
import { selectIsAppInitialized } from '../../redux/reducers/appSlice';
// import CustomTable from "./table/CustomTable";
import RowContextMenu from '../popup/row-context-menu/RowContextMenu';
import { selectFilterDialogState, selectSelectedTabFilterInfo } from '../../redux/reducers/tabFilterSlice';
import TabFilter from './tab/tab-filter/tab-filter';
import DeleteTabWarning from '../popup/deleteTab/Delete-Tab-Warning';
import TabNameDialog from '../dialog/settings/tab-settings/tab-modal/TabNameDialog';
import TabShareModal from '../popup/tab-action/TabShareModal';

function Content() {
  const selectedMenu = useAppSelector(selectMenu);
  const showFilterPopup = useAppSelector(selectIsFilterDeletePopup);
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const dispatch = useAppDispatch();
  const selectedTabFilter = useAppSelector(selectSelectedTabFilterInfo);
  const filterDialogState = useAppSelector(selectFilterDialogState);

  return (
    <div className='content-container'>
      {isAppInitialized && <Table></Table>}
      {/* {isAppInitialized && <CustomTable></CustomTable>} */}
      {isAppInitialized && <TabContainer></TabContainer>}
      <Menu
        id={selectedMenu}
        theme='menu'
        animation='scale'
        onVisibilityChange={(isVisible: boolean) => {
          if (!isVisible) {
            dispatch(setMenu(NONE_MENU_ID));
            dispatch(setIsTabContextMenuActive(false));
          } else {
            dispatch(setIsTabContextMenuActive(true));
          }
        }}>
        {selectedMenu == STATUS_MENU_ID && <LeaseStatus key={STATUS_MENU_ID}></LeaseStatus>}
        {selectedMenu == MARKET_MENU_ID && <Market key={MARKET_MENU_ID}></Market>}
        {selectedMenu == AUTHOR_MENU_ID && <Author key={AUTHOR_MENU_ID}></Author>}
        {selectedMenu == STATUS_FILTER_MENU_ID && (
          <LeaseFilter key={STATUS_FILTER_MENU_ID}></LeaseFilter>
        )}
        {selectedMenu == TAB_ACTION_MENU_ID && <TabAction key={TAB_ACTION_MENU_ID}></TabAction>}
        {selectedMenu == TAB_VIEW_SHARE_DOC_ID && <TabShareModal key={TAB_ACTION_MENU_ID}></TabShareModal>}
        {selectedMenu == TAB_VIEW_MENU_ID && <TabList key={TAB_VIEW_MENU_ID}></TabList>}
        {selectedMenu == CONTEXT_MENU_ID && <RowContextMenu key={CONTEXT_MENU_ID}></RowContextMenu>}
      </Menu>
      {showFilterPopup && (
        <div className='tab-filter-delete-warning'>
          <DeleteTabWarning key={DELETE_FILTER_WARNING_ID}></DeleteTabWarning>
        </div>
      )}
      {filterDialogState === FILTER_SETTINGS_STATE.FILTER_SETTINGS && (
        <div className='tab-filter-settings'>
          <TabFilter></TabFilter>
        </div>
      )}
       {filterDialogState === FILTER_SETTINGS_STATE.FILTER_RENAME && (
        <div className='tab-filter-rename'>
           <TabNameDialog></TabNameDialog>
        </div>
      )}
    </div>
  );
}
export default Content;

import { arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';
import { Item, ItemParams } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { toast } from 'react-toastify';
import {
  DELETE_FILTER_WARNING_ID,
  FILTER_SETTINGS_STATE,
  TAB_ACTIONS,
} from '../../../common/constants';
import { ClientEntity } from '../../../common/types/EntityTypes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  removeFilterFromDB,
  selectClients,
  selectInboxId,
  setClients,
  updateClientOrder,
} from '../../../redux/reducers/metadataSlice';
import {
  selectMenuTriggor,
  setMenu,
  setShowFilterDeletePopup,
} from '../../../redux/reducers/popupSlice';

import 'react-toastify/dist/ReactToastify.css';
import {
  setFilterDialogState,
  setSelectedTabFilterInfo,
} from '../../../redux/reducers/tabFilterSlice';
import { exportDocumentDataToExcel } from '../../../redux/reducers/documentsDataSlice';
import { selectUserID } from '../../../redux/reducers/userSlice';
import './TabShareModal.scss';
import { ChangeEvent, useState } from 'react';

function TabShareModal() {
  const tabActionTriggor = useAppSelector(selectMenuTriggor);
  const dispatch = useAppDispatch();
  const clients: ClientEntity[] = useAppSelector(selectClients);
  const currentClient = clients.find((c) => c.id == tabActionTriggor);
  const currentClientIndex = _.findIndex(clients, {
    id: tabActionTriggor,
  });
  const isInboxId = useAppSelector(selectInboxId) === tabActionTriggor;
  const userExternalId = useAppSelector(selectUserID);
  const [inputEmailAddress, setInputEmailAddress] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputEmailAddress(e.target.value);
  };

  return (
    <section className='tsm-container'>
      <div className='tsm-header'>Share this Tab with</div>
      <div className='tsm-input-container'>
        <input type='email' autoFocus placeholder='Enter email address' value={inputEmailAddress} onChange={(e) => handleChange(e)} />
      </div>
      <div className='tsm-footer'>
        <div className='tsm-footer-left'>
          <div className='tsm-btn'>Copy Link</div>  
        </div>
        <div className='tsm-footer-right'>
          <div className='tsm-btn'>Cancel</div>
          <div className='tsm-btn tsm-btn-orange'>Share</div>
        </div>
      </div>
    </section>
  );
}
export default TabShareModal;

import _ from 'lodash';
import { ClientEntity } from '../../../../../common/types/EntityTypes';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  removeFilterFromDB,
  selectClients,
  setClients,
  updateUserFilterName,
} from '../../../../../redux/reducers/metadataSlice';
import {
  selectMenuTriggor,
  setShowFilterDeletePopup,
} from '../../../../../redux/reducers/popupSlice';
import './TabModal.scss';
import { selectCurrentTab, setSelectedTab } from '../../../../../redux/reducers/dashboardSlice';
import {
  selectSelectedTabFilterInfo,
  setFilterDialogState,
  setSelectedTabFilterInfo,
} from '../../../../../redux/reducers/tabFilterSlice';
import { FILTER_SETTINGS_STATE } from '../../../../../common/constants';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
function TabNameDialog() {
  const dispatch = useAppDispatch();
  const tabActionTriggor = useAppSelector(selectMenuTriggor);
  const clients: ClientEntity[] = useAppSelector(selectClients);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const currentTabId = useAppSelector(selectCurrentTab);
  const tabFilterInfo = useAppSelector(selectSelectedTabFilterInfo);
  const [updatedFilterName, setUpdatedFilterName] = useState(tabFilterInfo.name);
  const [isTabNameDirty, setIsTabNameDirty] = useState(false);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setUpdatedFilterName(newValue);
    // setFilterNameErrorMessage(false);
    // setFilterNameExistsErrorMessage(false);
    if (!isTabNameDirty) setIsTabNameDirty(true);
  };

  function handleTabNameSave() {
    dispatch(updateUserFilterName({ ...tabFilterInfo, name: updatedFilterName })).then((e) => {
      // setFilterNameErrorMessage(false);
      // setFilterNameExistsErrorMessage(false);
      if (e.payload) {
        dispatch(
          setSelectedTabFilterInfo({
            ...tabFilterInfo,
            name: updatedFilterName,
          }),
        );
        dispatch(setFilterDialogState(FILTER_SETTINGS_STATE.FILTER_SETTINGS));
      }
    });
  }

  function handleCancel() {
    dispatch(setFilterDialogState(FILTER_SETTINGS_STATE.FILTER_SETTINGS));
  }

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  return (
    <section className='rename-dialog-container'>
      <div className='rdc-warning-text'>Enter a new Tab name</div>
      <div>
        <input
          className='rdc-filter-name-input'
          ref={inputRef}
          onChange={(event) => handleChange(event)}
          type='text'
          autoFocus
          value={updatedFilterName}
        />
      </div>
      <div className='rdc-footer'>
        <button className='rdc-button' onClick={handleCancel}>
          <span className='rdc-button-text'>Cancel</span>
        </button>
        <button className='rdc-button rdc-button-yellow' onClick={handleTabNameSave}>
          <span className='rdc-button-text'>Ok</span>
        </button>
      </div>
    </section>
  );
}
export default TabNameDialog;

import { arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';
import { Item, ItemParams } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { toast } from 'react-toastify';
import {
  DELETE_FILTER_WARNING_ID,
  FILTER_SETTINGS_STATE,
  TAB_ACTIONS,
} from '../../../common/constants';
import { ClientEntity } from '../../../common/types/EntityTypes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  removeFilterFromDB,
  selectClients,
  selectInboxId,
  setClients,
  updateClientOrder,
} from '../../../redux/reducers/metadataSlice';
import {
  selectMenuTriggor,
  setMenu,
  setShowFilterDeletePopup,
} from '../../../redux/reducers/popupSlice';

import 'react-toastify/dist/ReactToastify.css';
import {
  setFilterDialogState,
  setSelectedTabFilterInfo,
} from '../../../redux/reducers/tabFilterSlice';
import { exportDocumentDataToExcel } from '../../../redux/reducers/documentsDataSlice';
import { selectUserID } from '../../../redux/reducers/userSlice';

type TabActionProps = { tabId: string };

function TabAction() {
  const tabActionTriggor = useAppSelector(selectMenuTriggor);
  const dispatch = useAppDispatch();
  const clients: ClientEntity[] = useAppSelector(selectClients);
  const currentClient = clients.find((c) => c.id == tabActionTriggor);
  const currentClientIndex = _.findIndex(clients, {
    id: tabActionTriggor,
  });
  const isInboxId = useAppSelector(selectInboxId) === tabActionTriggor;
  const userExternalId = useAppSelector(selectUserID);

  function handleItemClick({ id, event, props, data, triggerEvent }: ItemParams<TabActionProps>) {
    let tempClients = [...clients];
    const oldIndex = _.findIndex(tempClients, {
      id: tabActionTriggor,
    });
    const filterId = tempClients[currentClientIndex].id;

    if (id == TAB_ACTIONS.MOVE_LEFT) {
      const oldTab = { ...tempClients[currentClientIndex] };
      const newTab = { ...tempClients[currentClientIndex - 1] };

      const oldViewOrder = oldTab.viewOrder;
      oldTab.viewOrder = newTab.viewOrder;
      newTab.viewOrder = oldViewOrder;

      tempClients[currentClientIndex] = oldTab;
      tempClients[currentClientIndex - 1] = newTab;

      dispatch(
        updateClientOrder(arrayMove(tempClients, currentClientIndex, currentClientIndex - 1)),
      );
    } else if (id == TAB_ACTIONS.MOVE_RIGHT) {
      const oldTab = { ...tempClients[currentClientIndex] };
      const newTab = { ...tempClients[currentClientIndex + 1] };

      const oldViewOrder = oldTab.viewOrder;
      oldTab.viewOrder = newTab.viewOrder;
      newTab.viewOrder = oldViewOrder;

      tempClients[currentClientIndex] = oldTab;
      tempClients[currentClientIndex + 1] = newTab;

      dispatch(
        updateClientOrder(arrayMove(tempClients, currentClientIndex, currentClientIndex + 1)),
      );
    } else if (id == TAB_ACTIONS.DELETE) {
      if (tempClients[currentClientIndex].default) {
        toast.error('Not Allowed. ' + tempClients[currentClientIndex].name + ' is DEFAULT Client.');
      } else {
        dispatch(setShowFilterDeletePopup(true));
      }
    } else if (id == TAB_ACTIONS.SETTINGS) {
      dispatch(setFilterDialogState(FILTER_SETTINGS_STATE.FILTER_SETTINGS));
      dispatch(setSelectedTabFilterInfo(tempClients[currentClientIndex]));
    } 
    else if (id == TAB_ACTIONS.EXPORT) {
      const fileUrl = process.env.REACT_APP_API_BASE_URL + 'export/' + filterId + '/' + userExternalId; // Replace with your file URL
      window.open(fileUrl, '_blank');
      // dispatch(exportDocumentDataToExcel({ filterId }));
    }
  }
  return (
    <section>
      {Object.entries(TAB_ACTIONS)
        .filter(([key, value]) => {
          if (
            value == TAB_ACTIONS.SET_DEFAULT ||
            value == TAB_ACTIONS.RENAME ||
            value == TAB_ACTIONS.MOVE_LEFT ||
            value == TAB_ACTIONS.MOVE_RIGHT
          )
            return false; 
          

          if (currentClientIndex == 0) {
            if (value == TAB_ACTIONS.MOVE_LEFT) return false;
          } else if (currentClientIndex == clients.length - 1) {
            if (value == TAB_ACTIONS.MOVE_RIGHT) return false;
          }
          if (currentClient?.default) {
            if (value == TAB_ACTIONS.DELETE) return false;
            else if (value == TAB_ACTIONS.SET_DEFAULT) return false;
          }

          if (isInboxId && value !== TAB_ACTIONS.DELETE && value !== TAB_ACTIONS.SETTINGS) {
            return true;
          } else if(!isInboxId){
            return true;
          } else {
            return false;
          }
        })
        .map(([key, value], index) => {
          return (
            <Item key={key} id={value} data={{ value }} onClick={handleItemClick}>
              <span className='menu-status-text'>{value}</span>
            </Item>
          );
        })}
    </section>
  );
}
export default TabAction;

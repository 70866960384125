import { ClientOfflineEntity } from "../common/types/DB";
import { db } from "./db";

/**
 * Clients DB Action
 */
export const fetchOfflineClients = () => {
  return db.clientValues.orderBy("viewOrder").toArray();
};

export const upsertClients = (values: ClientOfflineEntity[]) => {
  if (values) return db.clientValues.bulkPut(values);
};

export const getLastViewOrder = () => {
  return db.clientValues.orderBy("viewOrder").last();
};

export const insertClient = (value: ClientOfflineEntity) => {
  if (value) {
    return db.clientValues.put(value);
  }
};

export const updateClient = async (updatedData: ClientOfflineEntity) => {
  if (updatedData) {
    await db.transaction("rw", db.clientValues, async () => {
      // Find the item based on the specific column value
      const itemToUpdate = await db.clientValues
        .where("id")
        .equals(updatedData.id)
        .first();

      // Check if the item exists
      if (itemToUpdate) {
        // Update the item with the new data
        await db.clientValues.update(itemToUpdate.id, updatedData);
      }
    });
  }
};

export const deleteClients = (values: string[]) => {
  return db.clientValues.bulkDelete(values);
};

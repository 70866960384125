import { useEffect, useRef, useState } from 'react';
import './tf-tenant-template.scss';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  getHistoryForField,
  removeFilterChoices,
  selectSelectedFilterChoices,
  setEditableFilterChoice,
  setEditedFilterChoice,
  setFilterErrorMessage,
  setSelectedFilterChoices,
} from '../../../../../redux/reducers/tabFilterSlice';
import SaveTick from '../../../../../assets/images/dashboard/table/SaveTickIcon.svg';
import SaveTickDisabled from '../../../../../assets/images/dashboard/table/SaveTickIcon_Disabled.svg';
import WhiteTrashIcon from '../../../../../assets/images/dialog/filter/trashIcon_White.svg';
import BlackTrashIcon from '../../../../../assets/images/dialog/filter/Trashbin_Black.svg';
import WhiteEditIcon from '../../../../../assets/images/dialog/filter/Edit_White.svg';
import BlackEditIcon from '../../../../../assets/images/dialog/filter/Edit_Black.svg';
import WhiteCopyIcon from '../../../../../assets/images/dialog/filter/Duplicate_White.svg';
import BlackCopyIcon from '../../../../../assets/images/dialog/filter/Duplicate_Black.svg';
import LeftArrowIcon from '../../../../../assets/images/dialog/filter/left_add_arrow.svg';
import classNames from 'classnames';

function TabTenantEntity(props: { activeField: any; updateHistoryFlag: boolean }) {
  const dispatch = useAppDispatch();
  const [historyPhrasesList, setHistoryPhrasesList] = useState([] as string[]);
  const [inputExactPhrases, setInputExactPhrases] = useState('');
  const selectedExactPhrases = useAppSelector(selectSelectedFilterChoices).filter(
    (e) => e.fieldCode === props.activeField?.fieldCode,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasOption, setHasOption] = useState('has');
  const [selectedHistoryPhrases, setSelectedHistoryPhrases] = useState([] as string[]);
  const [editedPhrase, setEditedPhrase] = useState<{}>({});
  const [editExactPhrase, setEditExactPhrase] = useState('');
  const editInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.activeField?.code) {
      setInputExactPhrases('');
      dispatch(setFilterErrorMessage(false));
      setIsLoading(true);
      dispatch(getHistoryForField(props.activeField.fieldCode)).then((resp) => {
        const selectedPhrases = selectedExactPhrases
          .filter((data) => data.fieldCode === props.activeField?.fieldCode)
          .reduce<String[]>((aggregator, data) => {
            data.has.forEach((field) => {
              if (field.action != 'DELETE') aggregator.push(field.text);
            });
            data.hasNot.forEach((field) => {
              if (field.action != 'DELETE') aggregator.push(field.text);
            });
            return aggregator;
          }, []);
        const historyPhrases =
          resp.payload?.data?.filter((text: string) => !selectedPhrases?.includes(text)) || [];
        setHistoryPhrasesList(historyPhrases);
        setIsLoading(false);
      });
    }
  }, [props.activeField, props.updateHistoryFlag]);

  const handleAddPhraseFromTextBox = () => {
    const params = {
      fieldCode: props.activeField.fieldCode,
      [hasOption]: {
        isSelected: true,
        text: inputExactPhrases,
        action: 'UPDATE',
      },
    };
    if (
      selectedExactPhrases[0][hasOption !== 'has' ? 'has' : 'hasNot'].filter(
        (e) => e.text === inputExactPhrases,
      ).length > 0
    ) {
      dispatch(setFilterErrorMessage(true));
      return;
    }
    handleHistoryPhrasesList('remove', inputExactPhrases);
    setInputExactPhrases('');
    dispatch(setSelectedFilterChoices(params));
    dispatch(setFilterErrorMessage(false));
  };

  const deletePhrase = (phrase: any, hasOption: string) => {
    dispatch(
      removeFilterChoices({
        ...phrase,
        hasOption,
        fieldCode: props.activeField.fieldCode,
      }),
    );
    handleHistoryPhrasesList('add', phrase.text);
  };

  const editPhrase = (phrase: any, hasOption: string) => {
    dispatch(
      setEditableFilterChoice({
        ...phrase,
        hasOption,
        fieldCode: props.activeField.fieldCode,
        isEditable: true,
      }),
    );
    setEditedPhrase(phrase);
    setEditExactPhrase(phrase.text);
    // setTimeout(() => editInputRef.current?.focus(), 100);
  };

  const handleEditKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      dispatch(
        setEditableFilterChoice({
          ...editedPhrase,
          hasOption,
          fieldCode: props.activeField.fieldCode,
          isEditable: false,
        }),
      );
    } else if (event.key === 'Enter' && editExactPhrase !== '') {
      dispatch(
        setEditedFilterChoice({
          ...editedPhrase,
          editExactPhrase,
          hasOption,
          fieldCode: props.activeField.fieldCode,
          isEditable: false,
        }),
      );
    }
  };

  const handleEditOnBlur = () => {
    if (editExactPhrase !== '') {
      dispatch(
        setEditedFilterChoice({
          ...editedPhrase,
          editExactPhrase,
          hasOption,
          fieldCode: props.activeField.fieldCode,
          isEditable: false,
        }),
      );
    } else {
      dispatch(
        setEditableFilterChoice({
          ...editedPhrase,
          hasOption,
          fieldCode: props.activeField.fieldCode,
          isEditable: false,
        }),
      );
    }
  };

  const copyPhrase = (phrase: any, hasOption: string) => {};

  const selectHistoryPhraseToBeAdded = (phrase: string) => {
    if (!selectedHistoryPhrases.includes(phrase)) {
      setSelectedHistoryPhrases([...selectedHistoryPhrases, phrase]);
    } else {
      setSelectedHistoryPhrases((prevStrings) => prevStrings.filter((s) => s !== phrase));
    }
  };

  const addHistoryToExactPhrases = () => {
    if (selectedHistoryPhrases?.length > 0) {
      selectedHistoryPhrases.forEach((phrase) => {
        const params = {
          fieldCode: props.activeField.fieldCode,
          [hasOption]: {
            isSelected: true,
            text: phrase,
            action: 'UPDATE',
          },
        };
        dispatch(setSelectedFilterChoices(params));
      });
      setHistoryPhrasesList(
        historyPhrasesList.filter((phrase) => !selectedHistoryPhrases.includes(phrase)),
      );
      setSelectedHistoryPhrases([]);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      setInputExactPhrases('');
    } else if (event.key === 'Enter' && inputExactPhrases !== '') {
      handleAddPhraseFromTextBox();
    }
  };

  const handleHistoryPhrasesList = (event: string, text: string) => {
    if (event == 'remove') {
      setHistoryPhrasesList(historyPhrasesList.filter((phrase) => phrase != text));
    } else if (event == 'add') {
      historyPhrasesList.push(text);
      setHistoryPhrasesList(historyPhrasesList);
    }
  };

  return (
    <div className='tf-tenant-entity'>
      <div className='tfte-group tfte-group-exact-phrases'>
        <div className='tfte-label'>
          <div className='tfb-content-top-nav'>
            <span>Exact Phrases</span>
            <div className='tfbc-top-nav-content'>
              <div
                className={classNames('tfb-btn', {
                  active: hasOption === 'has',
                })}
                onClick={() => setHasOption('has')}>
                INCLUDES
              </div>
              <div
                className={classNames('tfb-btn', {
                  active: hasOption === 'hasNot',
                })}
                onClick={() => setHasOption('hasNot')}>
                EXCLUDES
              </div>
            </div>
          </div>
        </div>
        <div className='tfte-content-holder'>
          {hasOption === 'has' &&
            (selectedExactPhrases[0]?.has?.length > 0 ? (
              selectedExactPhrases[0]?.has.map((e, idx) => {
                return (
                  e.action !== 'DELETE' && (
                    <div className='tfte-content' key={idx}>
                      {e.isEditable && (
                        <>
                          <div className='tfte-text'>
                            <input
                              className='tfte-input'
                              ref={editInputRef}
                              value={editExactPhrase}
                              onBlur={() => handleEditOnBlur()}
                              onChange={(event) => setEditExactPhrase(event.target.value)}
                              onKeyDown={(event) => handleEditKeyDown(event)}
                            />
                          </div>
                          <div className='tfte-text-tools'>
                            <img
                              className={classNames({
                                'click-disabled': editExactPhrase === '',
                              })}
                              src={editExactPhrase !== '' ? SaveTick : SaveTickDisabled}
                              alt='Save'
                              title='Update'
                              onClick={handleEditOnBlur}
                            />
                          </div>
                        </>
                      )}
                      {!e.isEditable && (
                        <>
                          <div className='tfte-text'>{e.text}</div>
                          <div className='tfte-text-tools'>
                            <img
                              src={WhiteEditIcon}
                              alt='Edit'
                              title='Edit'
                              onClick={() => editPhrase(e, 'has')}
                            />
                            <img
                              src={WhiteCopyIcon}
                              alt='Copy'
                              title='Copy'
                              onClick={() => copyPhrase(e, 'has')}
                            />
                            <img
                              src={WhiteTrashIcon}
                              alt='Delete'
                              title='Delete'
                              onClick={() => deletePhrase(e, 'has')}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )
                );
              })
            ) : (
              <div className='tfte-history-loading'>No Exact Phrases Selected</div>
            ))}
          {hasOption === 'hasNot' &&
            (selectedExactPhrases[0]?.hasNot?.length > 0 ? (
              selectedExactPhrases[0]?.hasNot.map((e, idx) => {
                return (
                  e.action !== 'DELETE' && (
                    <div className='tfte-content' key={idx}>
                      {e.isEditable && (
                        <>
                          <div className='tfte-text'>
                            <input
                              className='tfte-input'
                              ref={editInputRef}
                              value={editExactPhrase}
                              onBlur={() => handleEditOnBlur()}
                              onChange={(event) => setEditExactPhrase(event.target.value)}
                              onKeyDown={(event) => handleEditKeyDown(event)}
                            />
                          </div>
                          <div className='tfte-text-tools'>
                            <img
                              className={classNames({
                                'click-disabled': editExactPhrase === '',
                              })}
                              src={editExactPhrase !== '' ? SaveTick : SaveTickDisabled}
                              alt='Save'
                              title='Update'
                              onClick={handleEditOnBlur}
                            />
                          </div>
                        </>
                      )}
                      {!e.isEditable && (
                        <>
                          <div className='tfte-text'>{e.text}</div>
                          <div className='tfte-text-tools'>
                            <img
                              src={WhiteEditIcon}
                              alt='Edit'
                              title='Edit'
                              onClick={() => editPhrase(e, 'hasNot')}
                            />
                            <img
                              src={WhiteCopyIcon}
                              alt='Copy'
                              title='Copy'
                              onClick={() => copyPhrase(e, 'hasNot')}
                            />
                            <img
                              src={WhiteTrashIcon}
                              alt='Delete'
                              title='Delete'
                              onClick={() => deletePhrase(e, 'hasNot')}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )
                );
              })
            ) : (
              <div className='tfte-history-loading'>No Exact Phrases Selected</div>
            ))}
        </div>
        <div className='tfte-group-input'>
          <input
            className='tfte-input'
            type='text'
            value={inputExactPhrases}
            onKeyDown={(event) => handleKeyDown(event)}
            onChange={(event) => {
              setInputExactPhrases(event.target.value)
            }}
          />
          <div
            className={classNames('tfte-input-save tfte-add-btn', {
              'tfte-save-disabled': inputExactPhrases.trim() === '',
            })}
            onClick={handleAddPhraseFromTextBox}>
            Add
          </div>
        </div>
      </div>
      <div className='tfte-add-btn-container'>
        <div
          className={classNames('tfte-add-btn', {
            'tfte-add-btn-disabled': selectedHistoryPhrases.length === 0,
          })}
          onClick={addHistoryToExactPhrases}>
          <img src={LeftArrowIcon} alt='<' className='tfte-add-direction' />
          Add
        </div>
      </div>
      <div className='tfte-group tfte-group-history'>
        <div className='tfte-label'>History</div>
        <div className='tfte-content-holder'>
          {isLoading === false ? (
            historyPhrasesList?.length > 0 ? (
              historyPhrasesList?.map((e, idx) => {
                return (
                  <div
                    className={classNames('tfte-content', {
                      'tfte-text-selected': selectedHistoryPhrases.indexOf(e) > -1,
                    })}
                    key={idx}>
                    <div className='tfte-text' onClick={() => selectHistoryPhraseToBeAdded(e)}>
                      {e}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='tfte-history-loading'>No History Found</div>
            )
          ) : (
            <div className='tfte-history-loading'>Loading History...</div>
          )}
        </div>
      </div>
    </div>
  );
}
export default TabTenantEntity;
